@import "../../../../assets/style-params.module";


.daypartsContent {
    overflow-y: auto;
    height: 450px;
    width: 450px;

    :global(.broadcast-day) {
        display: flex;
        padding: 16px 16px 0 16px;
        justify-content: space-between;

        :global(.broadcast-day-choices) {
            display: flex;

            >div {
                margin-left: 16px;
            }
        }
    }


    :global(.broadcast-timezone) {
        margin-left: 16px;
        margin-right: 8px;
        padding-top: 16px;
        padding-bottom: 8px;
        padding-right: 4px;
        display: flex;
        justify-content: space-between;
    }

    :global(.dayparts-weekparts-options) {
        padding: 0 16px 16px 16px;
    }

    :global(.standard-dayparts-options) {
        padding-bottom: 8px;
        margin-left: 16px;
        display: flex;

        > div {
            margin-right: 8px;
        }
    }

    :global(.addition) {
        color: $color-dove-grey;
        flex: 1;
        text-align: right;
        padding-right: 4px !important;
    }
}
.daypartsSets {
    padding: 16px 0px 0px 16px;
    :global(.dayparts-sets-options) {
        padding: 0px 0px 0px 0px;
    }

    >div {
        padding-bottom: 8px;
    }
}

.edit {
    color: $color-summer-sky;
    cursor: pointer;
    padding-right: 16px;
    max-width: 18px;
}