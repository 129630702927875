@import "../../../../assets/style-params.module";

.TVDaypartsPickerCard {
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    height: 512px;
    width: 464px;
    padding: 0;

    :global(.selected-tab) {
        box-sizing: border-box;
        height: 512px;
    }

    .bookmark {
        margin-left: 8px;
    }
}

.Footer {
    box-shadow: 0 -4px 21px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 16px;
}


.customDaypartsWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    > label {
        font-weight: bold;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 16px;
    }

    .customDaypartsPickers {
        display: flex;
        align-items: flex-end;
        margin-left: 8px;

        .customDaypartsPickersWrapper {
            display: flex;
            align-items: center;
            margin-top: 8px;
            > label {
                font-weight: bold;
                margin-left: 8px;
                margin-right: 8px;
            }

            .customDaypartsHourButtonWrapper {
                width: 90px;
            }
            .customDaypartsHourButton {
                width: 100%;
            }

            .customDaypartsError {
                & > button {
                    border-color: red;
                }
            }

            .customDaypartsHourButtonWithLabel {
                color: rgba($color: gray, $alpha: 0.3);
            }
        }
    }

    .customDaypartsButton {
        margin-left: 8px;
        display: flex;
        justify-content: flex-end;
    }
}

